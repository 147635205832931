import "./Footer.scss";

const Footer = () => {
  return (
    <div id="footer" className="Footer">
      <div className="wrapper">
        <h2>Feel free to reach out</h2>
        <a href="mailto:hello@usamabaig.com" title="Just do it!">
          hello@usamabaig.com
        </a>
        <div className="socials">
          <a
            href="mailto:usamabaig776@gmail.com"
            target="_blank"
            rel="noreferrer"
            title="Alternate email (usamabaig776@gmail.com)"
          >
            <i className="ri-mail-line"></i>
          </a>
          <a
            href="https://linkedin.com/in/usama-baig"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn (usamabaig-baig)"
          >
            <i className="ri-linkedin-fill"></i>
          </a>
          <a
            href="https://www.upwork.com/fl/usamabaig"
            target="_blank"
            rel="noreferrer"
            title="Upwork (Baig U.)"
            className="upwork"
          >
            <img src="./images/upwork-icon.svg" alt="" />
          </a>
          <a
            href="https://github.com/ubaig54"
            target="_blank"
            rel="noreferrer"
            title="GitHub (ubaig54)"
          >
            <i className="ri-github-fill"></i>
          </a>
          {/* <a
            href="https://instagram.com/ubaig54"
            target="_blank"
            rel="noreferrer"
            title="Instagram (ubaig54)"
          >
            <i className="ri-instagram-line"></i>
          </a>
          <a
            href="https://dribbble.com/ubaig54"
            target="_blank"
            rel="noreferrer"
            title="Dribbble (ubaig54)"
          >
            <i className="ri-dribbble-fill"></i>
          </a>
          <a
            href="https://youtube.com/codecamphere"
            target="_blank"
            rel="noreferrer"
            title="YouTube (CodeCamp)"
          >
            <i className="ri-youtube-fill"></i>
          </a> */} 
        </div>
      </div>
    </div>
  );
};

export default Footer;
