import "./Header.scss";

import Typewriter from "typewriter-effect";

const Header = () => {
  return (
    <header className="Header">
      <div className="wrapper">
        <div className="logo">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("ub")
                .pauseFor(5000)

                .typeString("aig54")
                .pauseFor(2500)
                .deleteChars(6)

                .typeString("sama<u>b</u><span>aig</span>")
                .pauseFor(2500)
                .deleteChars(8)

                .typeString("tterly<u>b</u><span>reathtaking</span>")
                .pauseFor(2500)
                .deleteChars(18)

                .typeString(
                  "sammamia <img src='/images/pinched-fingers.png' style='max-width: 20px; ' alt=''/>"
                )
                .pauseFor(2500)
                .deleteChars(9)

                .typeString("nique<u>b</u><span>lend</span>")
                .pauseFor(2500)
                .deleteChars(10)

                .typeString(
                  "r<u>b</u><span>ro</span> <img src='/images/heart-emoji.png' style='max-width: 21px; ' alt=''/>"
                )
                .pauseFor(2500)
                .deleteChars(5)

                .typeString("nleashed<u>b</u><span>rilliance</span>")
                .pauseFor(2500)
                .deleteChars(18)

                .typeString("nimaginably<u>b</u><span>eyond</span>")
                .pauseFor(2500)
                .deleteChars(18)

                .start();
            }}
            options={{
              loop: true,
              cursor: ".",
              skipAddStyles: true,
            }}
          />
        </div>
        <nav>
          <a href="#footer">Contact</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
