import "./App.scss";
import "remixicon/fonts/remixicon.css";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Home from "./screens/Home/Home";
import Work from "./screens/Work/Work";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Work />
      <Footer />
    </div>
  );
}

export default App;
