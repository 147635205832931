import "./Home.scss";

const Home = () => {
  return (
    <div className="Home">
      <div className="wrapper">
        <h1>
          I'm Usama Baig <span className="emoji">👋</span> A{" "}
          <span className="underline">freelance Web Developer</span>
          <br />I transform concepts into captivating online experiences.
        </h1>
      </div>
    </div>
  );
};

export default Home;
