import "./Work.scss";

import WorkItem from "../../components/WorkItem/WorkItem";

const Work = () => {
  return (
    <div className="Work">
      <div className="wrapper">
        <div className="heading">
          <h2>
            Selected
            <br />
            Work
          </h2>
          <p>Carefully handpicked from each category.</p>
        </div>
        <WorkItem
          category="wordpress"
          title="ARMA Group"
          desc="Specialise in improving the financial wellbeing of individuals and businesses."
          url="https://www.armagroup.com.au/"
        />
        <WorkItem
          category="react"
          title="The Sanctum Neo"
          desc="An NFT project with so much potential aiming to build a web3 community."
          url="https://the-sanctum-theta.vercel.app/"
        />
        <WorkItem
          category="php"
          title="Bad Days by Marvelous NFTs"
          desc="One of the very first play-to-earn game completely on blockchain."
          url="https://marvelousnfts.com"
        />
        <WorkItem
          category="shopify"
          title="DIBS Beauty"
          desc="A luxury personal care brand with a variety of cosmetic products for their customers."
          url="https://dibsbeauty.com/"
        />
        <div className="more">
          <div className="content">
            <h4>Want to see more?</h4>
            <p>
              Let's have a{" "}
              <a href="mailto:hello@usamabaig.com" title="hello@usamabaig.com">
                talk
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
