import "./WorkItem.scss";

const WorkItem = ({ category, title, desc, url }) => {
  const categoryIcon = () => {
    switch (category) {
      case "wordpress":
        return <i className="ri-wordpress-fill"></i>;
      case "react":
        return <i className="ri-reactjs-fill"></i>;
      case "php":
        return <img src="/images/php-icon.png" alt="" />;
      case "shopify":
        return <img src="/images/shopify-icon.svg" alt="" />;
      default:
        break;
    }
  };

  return (
    <div className="work-item">
      <div className="top">
        <h3 className="title">{title}</h3>
        <p className="desc">{desc}</p>
      </div>
      <div className="footer">
        <div className="footer-item">
          <a href={url} target="_blank" rel="noreferrer">
            <span className="icon">
              <i className="ri-window-line"></i>
            </span>
            <span className="text">Visit website</span>
          </a>
          <i className="ri-arrow-right-up-line"></i>
        </div>
        <div className="footer-item category-icon">{categoryIcon()}</div>
      </div>
    </div>
  );
};

export default WorkItem;
